.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.table-custom {
  max-width: 100%;
  margin: 0 auto;
}

.table-custom td {
  padding: 8px;
}

.vehicle-table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-table th, .vehicle-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.vehicle-table th {
  background-color: #f2f2f2;
  color: black;
  font-size: 12px;
}

.vehicle-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.vehicle-table tr:hover {
  background-color: #ddd;
}

.vehicle-table td {
  overflow-x: auto;
}

@media (max-width: 600px) {
  .vehicle-table th, .vehicle-table td {
    padding: 4px;
    font-size: 10px;
  }
}

.report-dashboard-container {
  padding: 16px;
  max-width: 100%;
  box-sizing: border-box;
}

.report-title {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1em;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

#date-picker {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  margin-top: 8px;
  font-size: 1em;
}

.report-section {
  margin-bottom: 1.5em;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.operator-report-list {
  list-style-type: none;
  padding: 0;
}

.operator-report-list li {
  margin-bottom: 0.5em;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.operator-report-list li:last-child {
  border-bottom: none;
}

.error-message {
  color: red;
  text-align: center;
}
